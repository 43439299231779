import {v4 as uuid} from 'uuid'
import React, {useEffect, useState, useCallback} from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {CardsNavButtonProps} from 'Event/template/Cards/Dashboard/CardsNavButton'
import {Cards, useCardsUpdate} from 'Event/template/Cards'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {NavButtonProps} from 'Event/Dashboard/components/NavButton'
import MainNavButtonConfigBase from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig'
import Settings from 'Event/template/Cards/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Settings'
import Styling from 'Event/template/Cards/Dashboard/MainNav/MainNavButton/MainNavButtonConfig/Styling'
import {usePruneAssets} from 'lib/asset'
import {useTemplateEditor} from 'organization/Event/TemplateEditor'

export type ButtonConfigProps<K extends NavButtonProps> = {
  button: K
  update: <T extends keyof K>(key: T) => (value: K[T]) => void
  disablePageSelect?: boolean
}

export function MainNavButtonConfig(
  props: ComponentConfigProps & {
    button: CardsNavButtonProps
    id?: string
  },
) {
  const {showing: isVisible, onClose, id = uuid(), button} = props

  const {
    control,
    handleSubmit,
    setValue,
    register,
    watch,
    formState,
  } = useForm()

  const [rules, setRules] = useState(button.rules)
  const pruneAssets = usePruneAssets()
  const {saved} = useTemplateEditor<Cards>()

  useEffect(() => {
    if (isVisible) {
      return
    }

    setRules(button.rules)
  }, [isVisible, button, id])

  const updateCards = useCardsUpdate()

  const update = (id: string, updated: CardsNavButtonProps) => {
    const image = updated.image

    if (saved.mainNav.buttons && id in saved.mainNav.buttons) {
      pruneAssets({image}, saved.mainNav.buttons[id])
    }

    updateCards({
      mainNav: {
        buttons: {
          [id]: updated,
        },
      },
    })
  }

  const insert = (button: CardsNavButtonProps) => {
    updateCards({
      mainNav: {
        buttons: {
          [id]: button,
        },
      },
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }
    updateCards({
      mainNav: {
        buttons: {
          [id]: REMOVE,
        },
      },
    })
  }, [id, updateCards])

  const save = (formData: any) => {
    const data: CardsNavButtonProps = {
      ...formData,
      rules,
    }

    if (id) {
      update(id, data)
      onClose()
      return
    }

    insert(data)
    onClose()
  }

  return (
    <MainNavButtonConfigBase
      button={button}
      id={id}
      control={control}
      register={register}
      watch={watch}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      onClose={onClose}
      handleSubmit={handleSubmit}
      formState={formState}
      save={save}
      isVisible={isVisible}
      showing={Boolean(id)}
      customSettings={
        <Settings
          button={button}
          control={control}
          watch={watch}
          register={register}
          setValue={setValue}
        />
      }
      customStyling={
        <Styling button={button} control={control} watch={watch} />
      }
    />
  )
}
