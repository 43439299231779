import React, {useCallback} from 'react'
import {Agenda} from 'Event/template/NiftyFifty/Dashboard/AgendaList'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/NiftyFifty/Dashboard/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'
import {useNiftyFiftyUpdate} from 'Event/template/NiftyFifty'

export function AgendaItemConfig(props: {agenda: Agenda; id: string}) {
  const {agenda, id} = props

  const save = useNiftyFiftyUpdate()

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    save({
      agendaList: {
        items: {
          [id]: REMOVE,
        },
      },
    })
  }, [id, save])

  return (
    <Config title="Agenda" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings agenda={agenda} id={id} />
      </SettingsPanel>
    </Config>
  )
}
