import React, {useCallback} from 'react'
import {CountDownTimerSettings} from 'Event/Dashboard/components/CountDownTimer'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer/TimerConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/Main/CountdownTimerSection/CountdownTimers/CountdownTimer/TimerConfig/Styling'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import Config from 'organization/Event/Configurable/Config'

export default function TimerConfig(props: {
  sectionId: string
  countDownTimer: CountDownTimerSettings
  id: string
}) {
  const {id, countDownTimer, sectionId} = props

  const save = useSaveTemplate()

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of timer to remove.")
    }

    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }, [id, sectionId, save])

  return (
    <Config title="Counterdown Timer" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings
          countDownTimer={countDownTimer}
          id={id}
          sectionId={sectionId}
        />
      </SettingsPanel>
      <StylingPanel>
        <Styling
          countDownTimer={countDownTimer}
          id={id}
          sectionId={sectionId}
        />
      </StylingPanel>
    </Config>
  )
}
