import {v4 as uuid} from 'uuid'
import {
  NavButtonProps,
  NavButtonWithSize,
} from 'Event/Dashboard/components/NavButton'
import React, {useEffect, useState, useCallback} from 'react'
import {ComponentConfigProps} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {useTownhallUpdate} from 'Event/template/Townhall'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import NavButtonConfigBase from 'Event/Dashboard/components/NavButton/NavButtonConfig/BaseConfig'
import Settings from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton/ActionButtonConfig/Settings'
import Styling from 'Event/template/Townhall/Dashboard/ActionNav/ActionButton/ActionButtonConfig/Styling'

export type ButtonConfigProps<K extends NavButtonProps> = {
  button: K
  update: <T extends keyof K>(key: T) => (value: K[T]) => void
  disablePageSelect?: boolean
}

export default function ActionButtonConfig(
  props: ComponentConfigProps & {
    button: NavButtonWithSize
    id?: string
  },
) {
  const {showing: isVisible, onClose, id = uuid(), button} = props

  const {
    control,
    handleSubmit,
    watch,
    register,
    setValue,
    formState,
  } = useForm()

  const [rules, setRules] = useState(button.rules)

  const set = useTownhallUpdate()

  useEffect(() => {
    if (isVisible) {
      return
    }

    setRules(button.rules)
  }, [isVisible, button, props.id])

  const update = (id: string, updated: NavButtonWithSize) => {
    set({
      actionNav: {
        buttons: {
          [id]: updated,
        },
      },
    })
  }

  const removeButton = useCallback(() => {
    if (!id) {
      throw new Error('Missing button id')
    }

    set({
      actionNav: {
        buttons: {
          [id]: REMOVE,
        },
      },
    })
  }, [set, id])

  const save = (formData: any) => {
    const data: NavButtonWithSize = {
      ...formData,
      rules,
    }

    update(id, data)
    onClose()
  }

  return (
    <NavButtonConfigBase
      button={button}
      id={id}
      control={control}
      register={register}
      watch={watch}
      setValue={setValue}
      rules={rules}
      setRules={setRules}
      removeButton={removeButton}
      onClose={onClose}
      handleSubmit={handleSubmit}
      save={save}
      formState={formState}
      isVisible={isVisible}
      showing={Boolean(id)}
      title="Action Button"
      customSettings={
        <Settings
          button={button}
          control={control}
          watch={watch}
          register={register}
          setValue={setValue}
        />
      }
      customStyling={
        <Styling button={button} control={control} watch={watch} />
      }
    />
  )
}
