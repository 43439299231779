import {v4 as uuid} from 'uuid'
import React, {useEffect, useState, useCallback} from 'react'
import {useDeleteFile} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceUpload'
import {Resource} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItem'
import ComponentConfig, {
  ComponentConfigProps,
  RemoveButton,
  SaveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {useForm} from 'react-hook-form'
import {ResourceListProps} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {useEditSidebarItem} from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/Lefty/Dashboard/Sidebar/SidebarItem/ResourceList/ResourceItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'

export function ResourceItemConfig(
  props: ComponentConfigProps & {
    id?: string
    resource: Resource
    list: ResourceListProps
  },
) {
  const {resource, id, showing, onClose} = props

  const deleteFile = useDeleteFile()

  const {handleSubmit, control, register, setValue, formState} = useForm()

  const [rules, setRules] = useState(resource.rules)
  const [isUrl, setIsUrl] = useState(resource.isUrl)
  const [filePath, setFilePath] = useState(resource.filePath)
  const {update: updateItem} = useEditSidebarItem()

  useEffect(() => {
    if (showing) {
      return
    }

    setRules(resource.rules)
    setIsUrl(resource.isUrl)
    setFilePath(resource.filePath)
  }, [resource, showing])

  const update = (id: string, data: Resource) => {
    updateItem({
      resources: {
        [id]: data,
      },
    })
  }

  const insert = (newResource: Resource) => {
    const id = uuid()

    updateItem({
      resources: {
        [id]: newResource,
      },
    })
  }

  const save = (
    form: Pick<Resource, 'name' | 'url' | 'isVisible' | 'icon' | 'actionId'>,
  ) => {
    const data: Resource = {
      ...form,
      rules,
      isUrl,
      filePath,
    }

    if (id !== undefined) {
      update(id, data)
      onClose()
      return
    }

    insert(data)
    onClose()
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error('Called remove outside of editing resource.')
    }

    if (resource.filePath) {
      deleteFile(resource.filePath).catch((e) => {
        // Log error, but prevent it from crashing
        // app
        console.error(e)
      })
    }

    updateItem({
      resources: {
        [id]: REMOVE,
      },
    })

    onClose()
  }, [deleteFile, id, onClose, resource, updateItem])

  return (
    <ComponentConfig
      title="Resource"
      onClose={onClose}
      showing={showing}
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          resource={resource}
          isUrl={isUrl}
          setIsUrl={setIsUrl}
          filePath={filePath}
          setFilePath={setFilePath}
        />
      </SettingsPanel>
      <RulesPanel rules={rules} setRules={setRules}>
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
        />
      </RulesPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          variant="outlined"
          aria-label="remove resource"
          onClick={remove}
          showing={Boolean(id)}
        >
          REMOVE RESOURCE
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}
