import React, {useEffect, useCallback} from 'react'
import {Agenda} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {
  useEditSidebarItem,
  useAutoUpdateSidebarItem,
} from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem'
import {v4 as uuid} from 'uuid'
import {useForm} from 'react-hook-form'
import moment from 'moment'
import Settings from 'Event/template/Cards/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'

export function AgendaItemConfig(
  props: {
    agenda: Agenda
    id?: string
  } & ComponentConfigProps,
) {
  const {showing, onClose, agenda, id} = props

  const {handleSubmit, control, watch, setValue, formState} = useForm()

  const {update: updateItem} = useEditSidebarItem()

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  useEffect(() => {
    if (!startDate || !endDate) {
      return
    }

    const shouldAdjustEndDate = moment(endDate).isBefore(moment(startDate))
    if (!shouldAdjustEndDate) {
      return
    }

    setValue('endDate', startDate)
  }, [startDate, endDate, setValue])

  const update = (id: string, updated: Agenda) => {
    updateItem({
      items: {
        [id]: updated,
      },
    })
  }

  const insert = (item: Agenda) => {
    const id = uuid()

    updateItem({
      items: {
        [id]: item,
      },
    })
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    updateItem({
      items: {
        [id]: REMOVE,
      },
    })

    onClose()
  }, [id, onClose, updateItem])

  useAutoUpdateSidebarItem({
    item: id ? {items: {[id]: watch()}} : {},
    when: showing && Boolean(id),
  })

  const save = (data: Agenda) => {
    if (id === undefined) {
      insert(data)
      onClose()
      return
    }

    update(id, data)
    onClose()
  }

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agenda"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings control={control} agenda={agenda} />
      </SettingsPanel>
      <Footer>
        <SaveButton />
        <RemoveButton
          aria-label="remove agenda"
          onClick={remove}
          showing={Boolean(id)}
        />
      </Footer>
    </ComponentConfig>
  )
}
