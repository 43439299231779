import React, {useCallback} from 'react'
import {v4 as uuid} from 'uuid'
import {useForm} from 'react-hook-form'
import {AgendaSettings} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/AgendaList'
import ComponentConfig, {
  ComponentConfigProps,
  SaveButton,
  RemoveButton,
  Footer,
} from 'organization/Event/DashboardConfig/ComponentConfigPanel'
import {REMOVE} from 'Event/TemplateUpdateProvider'
import {useEditSidebarItem} from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem'
import Settings from 'Event/template/SimpleBlog/Dashboard/Sidebar/SidebarItem/AgendaList/AgendaItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'

export function AgendaItemConfig(
  props: {
    agenda: AgendaSettings
    id?: string
  } & ComponentConfigProps,
) {
  const {showing, onClose, agenda, id} = props
  const {update: updateItem} = useEditSidebarItem()

  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    formState,
  } = useForm()

  const update = (id: string, updated: AgendaSettings) => {
    updateItem({
      items: {
        [id]: updated,
      },
    })
  }

  const insert = (item: AgendaSettings) => {
    const id = uuid()

    updateItem({
      items: {
        [id]: item,
      },
    })
  }

  const save = (data: AgendaSettings) => {
    if (id === undefined) {
      insert(data)
      onClose()
      return
    }

    update(id, data)
    onClose()
  }

  const remove = useCallback(() => {
    if (!id) {
      throw new Error("Missing 'id' of agenda to remove.")
    }

    updateItem({
      items: {
        [id]: REMOVE,
      },
    })

    onClose()
  }, [updateItem, id, onClose])

  return (
    <ComponentConfig
      showing={showing}
      onClose={onClose}
      title="Agenda"
      onSubmit={handleSubmit(save)}
      hasChanges={formState.isDirty}
    >
      <SettingsPanel>
        <Settings
          control={control}
          register={register}
          agenda={agenda}
          watch={watch}
          setValue={setValue}
        />
      </SettingsPanel>
      <Footer>
        <SaveButton type="submit" />
        <RemoveButton
          fullWidth
          variant="outlined"
          aria-label="remove agenda"
          onClick={remove}
          hidden={!id}
        >
          REMOVE AGENDA
        </RemoveButton>
      </Footer>
    </ComponentConfig>
  )
}
