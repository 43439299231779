import React, {useCallback} from 'react'
import {useDeleteFile} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceUpload'
import {Resource} from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItem'
import {REMOVE, useSaveTemplate} from 'Event/TemplateUpdateProvider'
import Settings from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/Settings'
import SettingsPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/SettingsPanel'
import RulesPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/RulesPanel'
import ScheduleInput from 'organization/Event/DashboardConfig/ComponentConfigPanel/ScheduleInput'
import StylingPanel from 'organization/Event/DashboardConfig/ComponentConfigPanel/StylingPanel'
import Styling from 'Event/template/Townhall/Dashboard/Main/ResourceSection/ResourceItemConfig/Styling'
import Config from 'organization/Event/Configurable/Config'
import {useConfig} from 'organization/Event/Configurable'

export function ResourceItemConfig(props: {
  id: string
  sectionId: string
  resource: Resource
}) {
  const {resource, id, sectionId} = props

  const deleteFile = useDeleteFile()

  const {
    form: {setValue, control},
  } = useConfig()

  const save = useSaveTemplate()

  const remove = useCallback(() => {
    if (resource.filePath) {
      deleteFile(resource.filePath).catch((e) => {
        // Log error, but prevent it from crashing app
        console.error(e)
      })
    }

    save({
      dashboardSections: {
        [sectionId]: {
          items: {
            [id]: REMOVE,
          },
        },
      },
    })
  }, [deleteFile, id, sectionId, resource, save])

  return (
    <Config title="Resource" onSave={save} onRemove={remove}>
      <SettingsPanel>
        <Settings resource={resource} id={id} sectionId={sectionId} />
      </SettingsPanel>
      <StylingPanel>
        <Styling resource={resource} id={id} sectionId={sectionId} />
      </StylingPanel>
      <RulesPanel
        rules={resource.rules}
        control={control}
        name={`dashboardSections.${sectionId}.items.${id}.rules`}
      >
        <ScheduleInput
          setValue={setValue}
          control={control}
          values={resource}
          fromName={`dashboardSections.${sectionId}.items.${id}.showingFrom`}
          untilName={`dashboardSections.${sectionId}.items.${id}.showingUntil`}
        />
      </RulesPanel>
    </Config>
  )
}
